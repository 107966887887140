import { StarRating } from '@hz-design-system/web-ui';
import React from 'react';
import average from '../../../utils/average';

import Classes from './SubScores.scss';
import { CarDealerReviewsSubscore } from '../../../../../../types/AppState';

type SubScoresProps = {
  subScores: CarDealerReviewsSubscore[];
};
const SubScores = ({ subScores }: SubScoresProps) => (
  <div>
    {!!subScores.length && (
      <div className={Classes.wrap}>
        {subScores.map((subScore, index) => {
          if (!subScore.value) {
            return null;
          }
          return (
            <div key={`subscore-${index}`} className={Classes.item}>
              <div className={Classes.value}>{subScore.value}</div>
              <StarRating label="" rating={average(subScore.overallScore)} />
              <div className={Classes.name}>{subScore.name}</div>
            </div>
          );
        })}
      </div>
    )}
  </div>
);

export default SubScores;
