import React from 'react';
import { Card } from '@hz-design-system/web-ui';
import TopSection from './TopSection/TopSection';
import ProfileHeroTabs from './ProfileHeroTabs/ProfileHeroTabs';
import Info from './Info/Info';
import Classes from './ProfileHero.scss';
import type { SellerProfile, CarDealerReviews } from '../../../../../types/SellerProfile';

interface ProfileHeroProps {
  isUserViewingOwnProfile: boolean;
  sellerProfile: SellerProfile;
  editProfileUrl: string;
  loggedInUserId: number;
  onClickSaveControl: () => void;
  saveControlLoading: boolean;
  isSellerSaved: boolean;
  userReviews: any;
  carDealerReviews: CarDealerReviews | null;
  onClickReviewsControl: () => void;
}

const ProfileHero = ({
  isUserViewingOwnProfile,
  sellerProfile,
  editProfileUrl,
  loggedInUserId,
  onClickSaveControl,
  saveControlLoading,
  isSellerSaved,
  userReviews,
  carDealerReviews,
  onClickReviewsControl,
}: ProfileHeroProps) => {
  return (
    <section className={`${Classes.root} hz-Page-element hz-Page-element--full-width`}>
      <Card>
        <TopSection {...sellerProfile} />
        <Info
          sellerId={sellerProfile.id}
          isUserViewingOwnProfile={isUserViewingOwnProfile}
          editProfileUrl={editProfileUrl}
          location={sellerProfile.location}
          isSellerSaved={isSellerSaved}
          periodSinceRegistrationDate={sellerProfile.periodSinceRegistrationDate}
          isSaveSellerControlVisible={loggedInUserId !== sellerProfile.id}
          onClickSaveControl={onClickSaveControl}
          saveControlLoading={saveControlLoading}
          userReviews={userReviews}
          carDealerReviews={carDealerReviews}
          onClickReviewsControl={onClickReviewsControl}
          hasSellerProfile={sellerProfile.hasSellerProfile}
        />
        {sellerProfile.hasSellerProfile && (
          <div className={Classes.tabWrap}>
            <ProfileHeroTabs sellerId={sellerProfile.id} sellerName={sellerProfile.title} />
          </div>
        )}
      </Card>
    </section>
  );
};

export default ProfileHero;
