import React from 'react';
import { EnvironmentConfig } from '../../../../types/AppState';

const defaultValues: EnvironmentConfig = {
  staticBaseUrl: '',
  xsrfToken: '',
  baseUrl: '',
  hostname: '',
  properties: {
    facebookAppId: '',
    twitterHandle: '',
  },
  tenant: '',
  links: {},
  social: { googleSdkUrl: '', googleClientID: '', facebookSdkUrl: '', facebookAppId: '' },
  threatMetrix: {
    domainUrl: '',
    tmsid: {
      timestamp: 0,
      signature: '',
      uuid: '',
    },
  },
  locale: '',
  gmap: { apiKey: '' },
};

const EnvironmentContext = React.createContext(defaultValues);

export const EnvironmentProvider = EnvironmentContext.Provider;
export const EnvironmentConsumer = EnvironmentContext.Consumer;

export default EnvironmentContext;
