import qs from 'query-string';

import { types, eventLabelsByType, utmSourcesByType, eventActions } from '../../constants/SocialShare';
import trackEvent from '../../utils/trackEvent';

const buildWhatsappUrl = (encodedURI: string, isMobile: boolean) => {
  if (isMobile) {
    return `whatsapp://send?text=${encodedURI}`;
  }
  return `https://web.whatsapp.com/send?text=${encodedURI}`;
};

const buildMessengerUrl = (encodedURI: string, hostname: string, appId?: string) =>
  `http://www.facebook.com/dialog/send?app_id=${appId}&link=${encodedURI}&display=popup&redirect_uri=${encodeURIComponent(
    `${hostname}/plaats/thanks-for-sharing`,
  )}`;

const buildFacebookUrl = (encodedURI: string, encodedMessage: string) =>
  `http://www.facebook.com/sharer/sharer.php?u=${encodedURI}&quote=${encodedMessage}`;

const buildTwitterUrl = (encodedURI: string, encodedMessage: string, twitterHandle?: string) =>
  `http://twitter.com/intent/tweet?url=${encodedURI}&text=${encodedMessage}&via=${twitterHandle}`;

const buildEmailUrl = (encodedURI: string, encodedMessage: string) =>
  `mailto:?body=${encodedURI}&subject=${encodedMessage}`;

export const addUtmParamsToLink = (link: string, type: string) => {
  const { url, query } = qs.parseUrl(link);
  const newQuery = {
    ...query,
    /* eslint-disable camelcase */
    utm_medium: 'social',
    utm_campaign: 'socialbuttons',
    utm_source: utmSourcesByType[type] || type,
  };
  const queryString = qs.stringify(newQuery);
  return `${url}?${queryString}`;
};

type BuildUrlForTypeParams = {
  type: string;
  linkToShare: string;
  message: string;
  socialShareConfig?: {
    facebookAppId?: string;
    twitterHandle?: string;
  };
  isMobile: boolean;
  hostname: string;
};

export const buildUrlForType = ({
  type,
  linkToShare,
  message,
  socialShareConfig = {},
  isMobile,
  hostname,
}: BuildUrlForTypeParams) => {
  const { facebookAppId, twitterHandle } = socialShareConfig;
  const encodedURI = encodeURIComponent(addUtmParamsToLink(linkToShare, type));
  const encodedMessage = encodeURIComponent(message);
  switch (type) {
    case types.WHATSAPP:
      return buildWhatsappUrl(encodedURI, isMobile);
    case types.MESSENGER:
      return buildMessengerUrl(encodedURI, hostname, facebookAppId);
    case types.FACEBOOK:
      return buildFacebookUrl(encodedURI, encodedMessage);
    case types.TWITTER:
      return buildTwitterUrl(encodedURI, encodedMessage, twitterHandle);
    case types.EMAIL:
      return buildEmailUrl(encodedURI, encodedMessage);
    default:
      return addUtmParamsToLink(linkToShare, 'unknown');
  }
};

export const trackShareList = (type: string) => {
  trackEvent({ eventAction: eventActions.SHARE_BEGIN, eventLabel: eventLabelsByType[type] || type });
};

export default { buildUrlForType, trackShareList };
