import breakpoints from '../constants/breakpoints';

const DEFAULT_IFRAME_WIDTH = 690;
const DEFAULT_IFRAME_HEIGHT = 690;

const calculateIFrameWidth = () => {
  const offset = 40;
  if (window.innerWidth < breakpoints.SMALL) {
    return window.innerWidth - offset;
  }
  return DEFAULT_IFRAME_WIDTH;
};

const calculateIFrameHeight = () => {
  const offset = 100;
  if (window.innerWidth < breakpoints.SMALL) {
    return window.innerHeight - offset;
  }
  return DEFAULT_IFRAME_HEIGHT;
};

const buildIFrameSrc = (sellerId: number) => `/experiences/user-reviews/${sellerId}`;

export { calculateIFrameWidth, calculateIFrameHeight, buildIFrameSrc };
