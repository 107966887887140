const NAME_SPACE = 'mp';

type Input = {
  name?: string;
  states?: string[];
  modifiers?: string[];
  utilities?: string[];
  icon?: string;
  custom?: string;
};

const format = (prefix: string = '', input: string[] = []): string =>
  input
    .filter((value) => Boolean(value))
    .map((value) => `${prefix}${value}`)
    .join(' ');

export default (input: Input = {}): string => {
  const component = `${NAME_SPACE}-${input.name}`;
  const modifiers = format(`${component}--`, input.modifiers);
  const states = format('is-', input.states);
  const utilities = format('u-', input.utilities);
  const icon = format('hz-svg-', [input.icon as string]);
  const custom = input.custom || '';

  return `${component} ${modifiers} ${states} ${utilities} ${icon} ${custom}`.replace(/  +/g, ' ').trim();
};
