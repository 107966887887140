import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nContextProvider, I18nInstanceType } from '@ecg-marktplaats/js-react-i18n';
import { Header } from '@hz-design-system/web-ui';
import SellerProfilePageApp from '../../apps/SellerProfilePage/SellerProfile';

import '../../../scss/site/global/global.scss';

export default (i18n: I18nInstanceType) => {
  const appState = window.__CONFIG__ || {};
  const environment = window.__ENV_CONFIG__ || {};
  const headerConfig = window.__HEADER_CONFIG__ || {};
  const rootContainer = document.getElementById('root');
  const headerRootContainer = document.getElementById('header-root');

  hydrateRoot(headerRootContainer as HTMLElement, <Header headerConfig={headerConfig} />);

  hydrateRoot(
    rootContainer as HTMLElement,
    <I18nContextProvider i18nInstance={i18n}>
      <SellerProfilePageApp appState={appState} environment={environment} />
    </I18nContextProvider>,
  );
};
