import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Tab, Tabs } from '@hz-design-system/web-ui';
import { lrpUrl } from '@ecg-marktplaats/aurora-node-url-builder';

type ProfileHeroTabsProps = {
  sellerId: number;
  sellerName: string;
};
const ProfileHeroTabs = ({ sellerId, sellerName }: ProfileHeroTabsProps) => {
  const { t } = useI18nContext();
  const sellerAdsUrl = lrpUrl({
    seller: {
      id: sellerId,
      name: sellerName,
    },
  });

  const handleAllAdsTabClick = () => {
    window.location.href = sellerAdsUrl;
  };

  return (
    <Tabs
      children={[
        <Tab isActive={true} label={t('sellerHeader.aboutUs')}></Tab>,
        <Tab onClick={handleAllAdsTabClick} label={t('sellerHeader.allAds')}></Tab>,
      ]}
    ></Tabs>
  );
};

export default ProfileHeroTabs;
